import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { numberToMoney } from '../../helpers';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 5,
  flexGrow: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'primary',
  },
}));

const CreditLineLinearProgress = ({ creditLine }) => {
  const rate = creditLine === null ? 0 : (creditLine.creditUsed / creditLine.limitAmount);
  const value = Math.min(rate, 1);
  const currency = creditLine?.currency?.isoCode;
  const creditUsed = creditLine === null
    ? 'Empresa sin Linea de Crédito'
    : `Monto Utilizado: ${numberToMoney(creditLine.creditUsed, currency)}`;
  const totalAmount = creditLine === null
    ? 'Empresa sin Linea de Crédito'
    : `Monto Disponible: ${numberToMoney(creditLine.limitAmount, currency)}`;
  return (
    <Stack direction="column" spacing={1}>
      <Tooltip title={creditUsed}>
        <BorderLinearProgress variant="determinate" value={Math.min(value, 1) * 100} />
      </Tooltip>
      <Typography noWrap sx={{ fontSize: 11 }}>{totalAmount}</Typography>
    </Stack>
  );
};

CreditLineLinearProgress.propTypes = {
  creditLine: PropTypes.shape({
    creditUsed: PropTypes.number,
    limitAmount: PropTypes.number,
    currency: PropTypes.shape({
      isoCode: PropTypes.string,
    }),
  }),
};

CreditLineLinearProgress.defaultProps = {
  creditLine: null,
};

export default CreditLineLinearProgress;
